<template>
	<figure ref="statsRef" class="stats flex flex--align gilroy matins m-0" :class="{'stats--portrait': portrait}">
		<div class="stats__amount-container sz-9 b9:sz-8 b10:sz-9 flex--no-shrink">
			<svg class="stats__background" width="583" height="581" viewBox="0 0 583 581" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path class="stats-svg-thick powder" d="M67.9518 214.442C48.9498 271.026 52.1107 332.711 76.7971 387.057C101.483 441.403 145.859 484.366 200.974 507.283C256.089 530.2 317.844 531.365 373.784 510.544C429.725 489.723 475.69 448.465 502.409 395.089" stroke="#E3F8F8" stroke-width="110.78" />
				<path class="peach counterclockwise slow" d="M141.785 286.942C141.275 316.557 149.559 345.658 165.588 370.565C181.617 395.471 204.672 415.066 231.837 426.87C259.003 438.674 289.058 442.157 318.203 436.88C347.348 431.602 374.274 417.801 395.575 397.22" stroke="#FFF1D0" stroke-width="17.78" />
				<path class="benjamin clockwise" d="M164.333 237.713C176.198 208.584 197.702 184.411 225.251 169.233C252.799 154.056 284.723 148.795 315.684 154.329" stroke="#94CC3F" stroke-width="3.8" stroke-dasharray="2.42 2.42" />
				<path class="summer stats-svg-thin" d="M117.443 180.591C136.777 149.693 163.987 124.498 196.277 107.593C228.568 90.6884 264.776 82.6826 301.184 84.398C337.592 86.1133 372.887 97.488 403.445 117.354C434.004 137.22 458.724 164.862 475.067 197.44" stroke="#FFE49D" stroke-width="3.78128" />
			</svg>
			<svg viewBox="0 0 190 190" width="190" height="190" class="stats__donut-chart">
				<circle v-if="!isPercent || ringsArray.length <= 1" class="stats__donut-ring" cx="95" cy="95" :r="RING_RADIUS"></circle>
				<circle v-if="!isPercent || ($amp && parseFloat(amount) > 100)" stroke-width="1" class="stats__donut-segment counterclockwise" cx="95" cy="95" :r="64" :stroke-dasharray="`150 ${calculcateCircumference(64)}`" stroke-dashoffset="-50"></circle>
				<template v-if="isPercent">
					<circle v-for="ring in ringsArray" :key="ring.key" :stroke-width="ring.strokeWidth" class="stats__donut-segment" cx="95" cy="95" :r="ring.radius" :stroke-dasharray="ring.dashArray" stroke-dashoffset="0" :style="`transition-delay: ${ring.transitionDelay}s; transition-duration: ${ring.transitionDuration}s;`"></circle>
				</template>
				<template v-if="isPercent && $amp && parseFloat(amount) <= 100">
					<circle :stroke-width="TOTAL_STROKE_WIDTH" class="stats__donut-segment" cx="95" cy="95" :r="RING_RADIUS" :stroke-dasharray="`${parseFloat(amount) / 100 * calculcateCircumference(RING_RADIUS)} ${calculcateCircumference(RING_RADIUS)}`" stroke-dashoffset="0"></circle>
				</template>
			</svg>
			<span class="stats__amount flex flex--align flex--center">{{ label ? label : amount }}</span>
		</div>
		<figcaption class="stats__content">
			<p v-if="description" class="stats__description sz-5 b3:sz-4 b10:sz-5 mb-0">{{ description }}</p>
			<p v-if="byLine" class="stats__by-line sz-4 b3:sz-3 b10:sz-4 mt-0 text-right">{{ byLine }}</p>
		</figcaption>
	</figure>
</template>

<script>
	import componentMountedEvent from '@cognitoforms/shared-components/src/mixins/content-component-mounted-event';

	const RING_RADIUS = 84;
	const TOTAL_STROKE_WIDTH = 14;
	const ANIMATION_SPEED = 1;

	export default {
		name: 'CStats',
		mixins: [componentMountedEvent],
		props: {
			amount: { type: String, default: '0' },
			description: { type: String, default: null },
			byLine: { type: String, default: null },
			label: { type: String, default: null },
			portrait: { type: Boolean, default: false }
		},
		data() {
			return {
				ringsArray: [],
				ampRings: Math.ceil(parseFloat(this.amount) / 100),
				ampStrokeWidth: this.adjustedStrokeWidth(Math.ceil(parseFloat(this.amount) / 100))
			};
		},
		computed: {
			isPercent() {
				return this.amount.indexOf('%') >= 0;
			}
		},
		created() {
			this.RING_RADIUS = RING_RADIUS;
			this.TOTAL_STROKE_WIDTH = TOTAL_STROKE_WIDTH; // todo remove when amp is removed
		},
		mounted() {
			this.buildRingsArray();
			this.initIntersectionObserver();
		},
		methods: {
			buildRingsArray() {
				const totalAmount = parseFloat(this.amount);
				const rings = Math.ceil(totalAmount / 100);
				const strokeWidth = this.adjustedStrokeWidth(rings);
				const innerRadius = RING_RADIUS - (TOTAL_STROKE_WIDTH / 2) + (strokeWidth / 2);
				const ringProperties = [];

				// Build an array of ring objects
				for (let i = 0; i < rings; i++) {
					const radius = this.calculateRingRadius(innerRadius, strokeWidth, i);
					const circumference = this.calculcateCircumference(radius);
					const ringObj = {
						key: i,
						radius,
						circumference,
						strokeWidth,
						dashArray: `0 ${circumference}`, // start at 0 for animation
						endingPercent: (100 * (i + 1)) > totalAmount ? totalAmount % 100 : 100,
						transitionDelay: this.calculateTransitionDelay(i),
						transitionDuration: ANIMATION_SPEED
					};
					if (this.$amp) {
						ringObj.dashArray = this.getDashArray(ringObj);
					}
					ringProperties.push(ringObj);
				}
				this.ringsArray = ringProperties;
			},
			initIntersectionObserver() {
				const options = {
					root: null,
					rootMargin: '0px',
					threshold: .8
				};
				const observer = new IntersectionObserver((entries) => {
					if (entries[0].isIntersecting) {
						this.animateStat();
					}
				}, options);
				observer.observe(this.$refs.statsRef);
			},
			getDashArray(ring) {
				return `${ring.circumference * (parseFloat(ring.endingPercent) / 100)} ${ring.circumference}`;
			},
			adjustedStrokeWidth(rings) {
				const ringsAndSpaces = ((rings * 2) - 1);
				return rings === 1 ? TOTAL_STROKE_WIDTH : (TOTAL_STROKE_WIDTH / ringsAndSpaces);
			},
			calculateRingRadius(innerRadius, strokeWidth, currentRing) {
				return innerRadius + (strokeWidth * 2 * currentRing);
			},
			calculcateCircumference(r) {
				return 2 * Math.PI * r;
			},
			calculateTransitionDelay(ring) {
				return ring * ANIMATION_SPEED * .15;
			},
			animateStat() {
				this.ringsArray.forEach(ring => {
					ring.dashArray = this.getDashArray(ring);
				});
			}
		}
	};
</script>

<style lang="scss">
$background-size: 456px;
$background-size-sm: $background-size - 80;

@mixin portrait-styles {
	flex-direction: column;
	border: 0;
	padding: $space__5 $space__4 $space__4 $space__4;
	text-align: center;

	.wiki & {
		min-width: 250px;
	}

	.stats__background {

		.stats-svg-thin,
		.stats-svg-thick, {
			display: none;
		}
	}

	.stats__content {
		margin: $space__5 0 0 0;

		@include b-range($b9, $b10) {
			margin-top: calc($space__5 + $space__4);
		}
	}

	.stats__description {
		max-width: $background-size;
	}

	.stats__by-line {
		margin-top: $space__2;
		text-align: center;
	}
}

.stats {
	border-top: 1px solid $brady;
	border-bottom: 1px solid $brady;
	overflow: hidden;
	padding: $space__4 $space__4 $space__4 150px;

	.wiki & {
		@include b-down($b5) {
			float: none;
			margin: auto;
		}
	}

	&__amount-container {
		position: relative;
		font-weight: $weight__display--bold;
		line-height: 0;

		span {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			top: 0;
		}
	}

	&__background {
		position: absolute;
		left: 50%;
		top: 50%;
		width: $background-size;
		max-width: none;
		height: $background-size;
		margin: calc(-1 * $background-size / 2) 0 0 calc(-1 * $background-size / 2);

		path {
			transform-origin: center center;
		}
	}

	&__content {
		z-index: $z1;
		margin-left: $space__6;
	}

	&__description {
		font-weight: $weight__display--bold;
	}

	&__by-line {
		font-weight: 300;
	}

	&__donut-chart {
		flex-grow: 0;
		transform: rotate(-90deg);

		circle {
			fill: transparent;
		}
	}

	&__donut-ring {
		stroke: $mapplethorpe;
		stroke-dasharray: 2px 5px;
		stroke-width: 14px;
	}

	&__donut-segment {
		transform-origin: center center;
		transition-property: stroke-dasharray;
		stroke: $thetis;
	}

	@include b-range($b9, $b10) {
		padding: $space__4 $space__4 $space__4 80px;

		&__donut-chart {
			width: 150px;
			height: 150px;
		}

		&__background {
			width: $background-size-sm;
			height: $background-size-sm;
			margin: calc(-1 * $background-size-sm / 2) 0 0 calc(-1 * $background-size-sm / 2);
		}

		&__content {
			margin-left: $space__5;
		}
	}

	@include b-down($b9) {
		@include portrait-styles;
	}

	&--portrait {
		@include portrait-styles;
	}
}
</style>